
// css Part
import "./main.scss";

console.log('Hello from Webpack');

// Whole bootstrap
import 'bootstrap';


